import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Container,
  Button,
  Divider,
  Box,
  Heading,
  Select,
  HStack
} from '@chakra-ui/react'
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useState } from 'react';


const Rankings = () =>{
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPosition, setCurrentPosition] = useState("qb");

  useEffect(() =>{
    const fetchData = async () => {
      const initialResult = await fetch(`https://ffrankings-b2a5acc9f2c1.herokuapp.com/${currentPosition}_data`);
      const jsonResult = await initialResult.json();
      jsonResult.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
      setTableData(jsonResult);
      setLoading(false);
    }
    fetchData();
  }, [currentPosition])
  
  const variants = {
    hidden: { opacity: 0, x: 0, y: 20 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: -0, y: 20 }
  }
  if (isLoading){
    return(
      <Box height={1000}></Box>
    )
  }

  return(
    <motion.div
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.4, type: 'easeInOut' }}
      style={{ position: 'relative' }}
    >
      <Container maxW='2xl'>
        <Box flexGrow={1}>
          <Heading as='h2' size='lg' variant='page-title'>
            Fantasy Football Draft Rankings (2023)
          </Heading>
          <p>PPR Rankings generated by multiple ML models </p>
        </Box>
      </Container>
      <Container maxW='2xl' marginTop={30}>
        <Divider marginBottom={1}/>
        <HStack>
          <Button 
            size='sm' 
            variant='ghost' 
            textColor={currentPosition === "overall" ? "white" : "#9eacbe"} 
            onClick={() => {setCurrentPosition("overall")}}
          > 
            Overall
          </Button>
          <Button 
            size='sm' 
            variant='ghost' 
            textColor={currentPosition === "qb" ? "white" : "#9eacbe"} 
            onClick={() => {setCurrentPosition("qb")}}
          > 
            QB
          </Button>
          <Button 
            size='sm' 
            variant='ghost' 
            textColor={currentPosition === "rb" ? "white" : "#9eacbe"} 
            onClick={() => {setCurrentPosition("rb")}}
          > 
            RB
          </Button>
          <Button 
            size='sm' 
            variant='ghost' 
            textColor={currentPosition === "wr" ? "white" : "#9eacbe"} 
            onClick={() => {setCurrentPosition("wr")}}
          > 
            WR
          </Button>
          <Button 
            size='sm' 
            variant='ghost' 
            textColor={currentPosition === "te" ? "white" : "#9eacbe"} 
            onClick={() => {setCurrentPosition("te")}}
          > 
            TE
          </Button>
          <Button 
            size='sm' 
            variant='ghost' 
            textColor={currentPosition === "flex" ? "white" : "#9eacbe"} 
            onClick={() => {setCurrentPosition("flex")}}
          > 
            Flex
          </Button>
        </HStack>
        <Divider marginTop={1}/>
      </Container>
      <Container maxW='2xl'>
        <TableContainer>
          <Table variant='simple' size='sm'>
            <Thead>
              <Tr>
                <Th>Rank</Th>
                <Th>Player</Th>
                <Th>Pos</Th>
                <Th>Team</Th>
                <Th isNumeric>Projected (PPR)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tableData.map((obj) => {
                return(
                  <Tr key = {obj._id}>
                    <Td>{obj.rank}</Td>
                    <Td>{obj.name}</Td>
                    <Td>{obj.position}</Td>
                    <Td>{obj.team}</Td>
                    <Td isNumeric>{obj.PPR}</Td>
                  </Tr>
                ) 
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </motion.div>
    
  );
}
export default Rankings;